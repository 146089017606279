<template>
   <el-footer style="height:285px">
        <footer-bottom></footer-bottom>
        <footer-copy></footer-copy>
   </el-footer>
</template>
<script>
import FooterBottom from "@/components/Footer/footerbottom";
import FooterCopy from "@/components/Footer/footercopy";
export default {
  components: {
    FooterBottom,
    FooterCopy
  }
};
</script>
<style lang="less" scoped>
.el-footer{
padding:0
}
</style>