<template>
  <el-container>
    <nav-main></nav-main>
    <el-main>
      <div class="bannerbox">
        <div class="container">
          <h2 class="tittleh2">关于我们</h2>
          <p class="titp">About us</p>
        </div>
      </div>
      <div class="centerbox">
        <div class="leftbox">
          <img src="../../assets/imgs/webimg/aboutleft.png" alt />
        </div>
        <div class="rightbox">
          <h2 class="tittleh2">小旗鱼科技</h2>
          <p
            class="titp"
          >
          我们是一群怀揣着梦想的年轻团队。凭借自身对产品的理解及实际中多场景使用的习惯，我们努力的打造了一份更好服务、更好用户体验的产品。<br/>

          简单、明了、实用是我们的开发主旨，为此我们不断的改进和完善产品。<br/>
          如果您在使用中有什么问题，或是有更好的建议，欢迎联系我们！</p>
          <h2 class="tittleh2">联系我们</h2>
          <dl class="aboutcontent">
            <dt>
              <i class="iconfont iconbianzu121"></i>地址：
            </dt>
            <dd>北京海淀区</dd>
          </dl>
          <dl class="aboutcontent">
            <dt>
              <i class="iconfont iconbianzu111"></i>邮箱：
            </dt>
            <dd>bean@opiping.com</dd>
          </dl>
          <dl class="aboutcontent">
            <dt>
              <i class="iconfont iconbianzu131"></i>公众号：
            </dt>
            <dd>豆豆记</dd>
          </dl>
        </div>
      </div>
       <footer-all></footer-all>
    </el-main>
  </el-container>
</template>
<script>
import NavMain from "@/components/nav/navMain";
import FooterAll from "@/components/Footer/footerall";
export default {
  components: {
    NavMain,
    FooterAll
  },
};
</script>
<style lang="less" scoped>
.el-container {
  height: 100%;
  flex-direction: column;
  .el-container {
  }
  .el-main {
    padding: 0;
  }
}

.bannerbox {
  height: 90px;
  background: url("../../assets/imgs/webimg/aboutbannerbg.png") no-repeat;
  background-size: 100% 90px;
  box-sizing: border-box;
  .titp {
    font-size: 12px;
    color: #2f95e3;
  }
  .tittleh2 {
    font-size: 20px;
    color: #2f95e3;
    margin: 22px 0 5px;
    font-weight: bold;
  }
}
.centerbox {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  padding: 90px 0;
  box-sizing: border-box;
  .leftbox {
    margin: 50px 120px 0 80px;
  }
  .titp {
    font-size: 14px;
    color: #666;
    line-height: 30px;
    margin-bottom: 30px;
  }
  .tittleh2 {
    box-sizing: border-box;
    font-size: 19px;
    color: #111;
    font-weight: bold;
    margin-bottom: 20px;
  }
}
.container {
  width: 1200px;
  margin: 0 auto;
  padding-left: 95px;
  overflow: hidden;
  box-sizing: border-box;
}
.aboutcontent {
  display: flex;
  line-height: 30px;
  margin-bottom: 10px;
  .iconfont {
    color: #23b7e5;
    font-size: 24px;
    margin-right: 10px;
    float: left;
  }
  dt {
    width: 108px;
  }
  dd {
    flex: 1;
  }
}
</style>