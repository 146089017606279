import axios from 'axios'
import { setApi, setNewData } from '@/utils/base.js'
const api = setApi()
/*
登录
geteway api：[POST] http://xapi-test.beanotes.com/login
local api：http://127.0.0.1:6002/login
*/
export const login = (data) => {
    return axios.post(api + `/login`, data)
}
/*
用户注册检查
geteway api：[POST] http://xapi-test.beanotes.com/register/check
local api：http://127.0.0.1:6002/register/check*/

export const registerCheckRequest = (data) => {
    return axios.post(api + `/register/check`, data)
}
/*
注册
geteway api：[POST] http://xapi-test.beanotes.com/register
local api：http://127.0.0.1:6002/register
*/
export const registerRequest = (data) => {
    return axios.post(api + `/register`, data)
}
/*
忘记密码
geteway api：[POST] http://xapi-test.beanotes.com/forget
local api：http://127.0.0.1:6002/forget
*/
export const forgetRequest = (data) => {
    return axios.post(api + `/forget`, data)
}

/*
重置密码
geteway api：[GET] http://xapi-test.beanotes.com/forget/reset
local api：http://127.0.0.1:6002/forget/reset
*/
export const forgetResetRequest = (data) => {
    return axios.post(api + `/forget/reset`, data)
}
/**
 * 获取未读消息
geteway api：
[GET] http://xapi-test.beanotes.com/message/unread
local api：
http://127.0.0.1:6002/message/unread
 */
export const messageUnreadRequest = () => {
    return axios.get(api + `/message/unread`)
}