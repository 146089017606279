<template>
  <div class="footerbottom">
    <div class="containter">
      <div class="one">
        <div class="logo">
          <img src="../../assets/imgs/logo.png" alt />
        </div>
        <p class="titp">致力于为个人或团队，提供在线图书、专栏等的知识管理及创作，打造轻松简单的知识体系</p>
      </div>
      <div class="one two">
        <h2 class="tittleh2">旗下产品</h2>
        <ul>
          <li @click="jump('opiping')" >Opiping一站式Api解决方案</li>
          <li>豆豆记-专业知识创作社区</li>
        </ul>
      </div>
      <div class="one two">
        <h2 class="tittleh2">公司</h2>
        <ul>
          <li>
            <router-link :to="{name:'about'}" tag="span">关于我们</router-link>
          </li>
          <li><router-link :to="{name:'question'}" tag="span">常见问题</router-link></li>
        </ul>
      </div>
      <div class="one two three">
        <h2 class="tittleh2">微信扫码关注</h2>
        <div class="imgbox">
          <img src="../../assets/imgs/webimg/beanotes.jpg" alt="豆豆记微信公众号" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    //链接跳转
    jump(type){
      switch(type){
        case "opiping":
          window.open("https://www.opiping.com", '_blank');break;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.footerbottom {
  background: #4a4a4a;
  height: 245px;
  overflow: hidden;
  .logo {
    width: 170px;
    height: auto;
    margin: 30px 0 15px 0;
    img {
      width: 100%;
    }
  }
  .containter {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .one {
      width: 210px;
      box-sizing: border-box;
      color: #fff;
      ul > li {
        font-size: 14px;
        color: #eee;
        line-height: 28px;
        cursor: pointer;
        &:hover{
          color: #fff;
        }
      }
    }
    .two {
      padding-top: 60px;
    }
    .three {
      width: 100px;
      text-align: center;
    }
    .tittleh2 {
      font-size: 16px;
      color: #ffffff;
      line-height: 28px;
      margin-bottom: 30px;
    }
    .titp {
      font-size: 14px;
      color: #ffffff;
      line-height: 28px;
    }
  }
}
</style>