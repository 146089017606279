<template>
  <el-header>
    <div class="navwrap">
      <a href="#" class="logobox">
        <img src="../../assets/imgs/logo.png" alt />
      </a>
      <el-menu
        router
        :default-active="$route.path"
        class="el-menu-top"
        mode="horizontal"
        text-color="#222"
        active-text-color="#2F95E3"
      >
        <el-menu-item index="/index">首页</el-menu-item>
        <el-menu-item index="/bookMain/bookAll">图书</el-menu-item>
        <el-menu-item index="/columnMain/columnAll">专栏</el-menu-item>
        <el-menu-item index="/teamsMain">团队</el-menu-item>
        <el-menu-item index="/download">下载</el-menu-item>
      </el-menu>
       <header-person class="personbox"></header-person>
    </div>
   
  </el-header>
</template>
<script>
import HeaderPerson from "@/components/Header/headerPerson";
export default {
  components: {
   HeaderPerson
  },
};
</script>
<style lang="less" scoped>
.navwrap {
  width: 1200px;
  margin: 0 auto;
  display: flex;
}
.el-header {
  background-color: #fff;
  box-shadow: 0 0px 10px #ccc;
}
.el-menu-top {
  flex: 1;
}
.logobox {
  width: 100px;
  height: 42.5px;
  margin: 14px 50px 0 0;
  img {
    width: 100%;
  }
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
  & > li {
    margin: 0 10px;
  }
}

</style>