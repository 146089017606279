<template>
  <div class="right-nav-box">
    <el-input size="medium" placeholder="请输入内容" class="searchinp" v-model="searchinp" @blur="gotoSearch" @keyup.enter.native="gotoSearch"></el-input>
    <el-badge :value="count>0?count :''" :max="99" class="item" v-if="token">
     <router-link class="iconfont iconxiaoxi notices" :to="{name:'noticeSystem'}" tag="i"></router-link>
    </el-badge>
    <el-dropdown class="person" @command="handleCommand" v-if="token">
      <span class="el-dropdown-link">
        <img :src="avatar" alt="" class="personimg">
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="person">个人中心</el-dropdown-item>
        <el-dropdown-item command="quit">退出</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div class="loginbtnbox" v-else>
      <router-link  :to="{name:'login'}" tag="i">
        <el-button type="primary" size="mini">登录</el-button>
        </router-link>
        <router-link class="loginbtn" :to="{name:'register'}" tag="i">
        <el-button   size="mini"  type="primary" plain>注册</el-button>
        </router-link>    
    </div>
  </div>
</template>
<script>
import { removeToken,removeUser,removeAvatar,getToken } from "@/utils/auth.js";
import { messageUnreadRequest } from "@/api/user.js";
export default {
  data(){
    return {
      searchinp:'',
      count:0,
    }
  },
   created() {
    this.getMessageUnread();
  },
  methods: {
    handleCommand(command) {
      if (command == "quit") {
        this.handelquit();
      } else {
        this.$router.push({ name: "persionColumnMy" });
      }
    },
    gotoSearch(){
      if(this.searchinp){
      this.$router.push({ name: "searchBook",query:{wd:this.searchinp} });
      }

    },
    handelquit(){
      this.$confirm('退出当前账号, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          removeToken()
          removeUser()
          removeAvatar()
          this.$router.push({
              name: "notes"
            });
            location.reload()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
     async getMessageUnread(val) {
      try {
      
        val=val || '1'
        const result = await messageUnreadRequest();
        if (result.status == 200) {
           this.count= result.data.count;
        }
      } catch (err) {}
    }
  },
  computed: {
    avatar() {
      return this.$store.state.avatar;
    },
    token(){
      return getToken()
    }
  }
};
</script>
<style lang="less" scoped>
.right-nav-box {
  width: 330px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.searchinp {
  width: 220px;
}
.person {
  cursor: pointer;
}
.personimg {
  width: 30px;
  height: 30px;
  border-radius: 80px;
}
.notices {
  cursor: pointer;
  width: 29px;
  height: 29px;
  line-height: 29px;
  display: block;
  text-align: center;
  border-radius: 100px;
  color: #cecece;
  border: 1px solid #cecece;
  &:hover{
    background: #fdfdfd;
     color: #666;
  }
}
.router-link-active{
 color: #fff;
  background: #409EFF;
    border: 1px solid #409EFF;
}
.loginbtnbox{
  /deep/.el-button--mini, /deep/.el-button--mini.is-round{
    padding: 7px 10px;
    background: #72c4d6;
    border: 1px solid #72c4d6;
  }
  .loginbtn{
     /deep/.el-button--mini, /deep/.el-button--mini.is-round{
background: #e0f4f9;
    border: 1px solid #72c4d6;
    color: #72c4d6;
     }
    margin-left: 5px;
    
  }
}
</style>