<template>
  <div class="footerbottom">Copyright@2020 北京小旗鱼科技有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff;">京ICP备20005419号-2</a></div>
</template>
<script>
export default {};
</script>
<style lang="less" scoped>
.footerbottom {
  text-align: center;
  background: #393939;
  height: 40px;
  line-height: 40px;
  color: #fff;
  opacity: 0.6;
  font-size: 12px;
}
</style>